import { ApiOptions } from '@/types/util'
import axios, { AxiosInstance, AxiosResponse } from 'axios'
import { useAuthStore } from '@/store/auth'
import { deserializeAuthToken } from '@/mappers/auth'
import { RefreshTokenRequest } from '@/types/auth/api'

export default defineNuxtPlugin(nuxtApp => {
    const runtimeConfig = nuxtApp.$config
    const apiHost = runtimeConfig.public.API_HOST

    let refreshCount: number = 0

    const api = (options: ApiOptions = {}) => {
        let ignoreToken = false

        if (options && options.usePublicHost === true) {
            ignoreToken = true
        }

        if (!options.headers) {
            options.headers = {
                'Content-Type': `application/json`,
                'Accept': `application/json`
            }
        }

        if (options.version) {
            options.headers = {
                ...options.headers,
                'API-Version': options.version
            }
        }

        // Create an Axios instance with the provided base URL
        const axiosClient: AxiosInstance = axios.create({
            baseURL: apiHost,
            headers: options.headers
        })

        if (!ignoreToken) {
            // Add request interceptors to handle authentication or any other logic
            axiosClient.interceptors.request.use(
                config => {
                    const authStore = useAuthStore()

                    if (authStore.getAccessToken) {
                        config.headers.Authorization = `Bearer ${authStore.getAccessToken}`
                    }

                    return config
                },
                error => {
                    return Promise.reject(error)
                }
            )

            // Add response interceptors to handle errors or refresh tokens
            axiosClient.interceptors.response.use(
                (response: AxiosResponse) => {
                    // Handle successful responses here
                    return response
                },
                async error => {
                    // Handle error response here
                    if (
                        error.response?.status === 401 ||
                        error.response?.status === 403
                    ) {
                        if (refreshCount === 0 && error.config.url !== `/v1/logout`) {
                            const newToken = await refreshAPIToken()
                            error.config.headers.Authorization = `Bearer ${newToken}`
                            return axiosClient(error.config)
                        }
                    }

                    return Promise.reject(error)
                }
            )
        }

        return axiosClient
    }

    // Define a function to refresh the API token
    async function refreshAPIToken(): Promise<string> {
        const authStore = useAuthStore()

        if (authStore.getRefreshToken === undefined) {
            authStore.clearAuthToken()
            return Promise.reject(undefined)
        }

        const data: RefreshTokenRequest = {
            refresh_token: authStore.getRefreshToken
        }

        const options = {
            method: `POST`,
            data: {data: data},
            headers: {
                'Content-Type': `application/json`,
                'Accept': `application/json`
            },
            url: `${apiHost}/v1/auth/refresh`
        }

        refreshCount++

        return axios(options)
            .then(async response => {
                const token = deserializeAuthToken(response.data.data)
                authStore.setAuthToken(token)
                refreshCount = 0
                return Promise.resolve(token.accessToken)
            })
            .catch(e => {
                authStore.clearAuthToken()
                refreshCount = 0
                return Promise.reject(e)
            })
    }

    return {
        provide: {
            api: api
        }
    }
})
