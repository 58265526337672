import { each, isArray, isPlainObject, isString, omit } from 'lodash-es'

/**
 * Sanitizes value for storage
 */
const sanitizeValue = (value: any): any => {
    if (isPlainObject(value) || isArray(value)) {
        each(value, (v: any, key: any) => {
            value[key] = sanitizeValue(v)
        })
    }

    if (isString(value)) {
        return value.replace(/(\u2028)|(\u2029)/g, ``)
    }

    return value
}

class SessionStore {
    section: string
    ttl?: number
    storage: Storage

    constructor(section: string, ttl?: number) {
        this.section = `church-podcast-:${section}`
        this.ttl = ttl
        this.storage = ttl ? localStorage : sessionStorage
    }

    /**
     * Sets an item in Session Storage
     */
    set(key: string, value: any) {
        const getItem = this.storage.getItem(this.section)
        const item = getItem ? JSON.parse(getItem) : { values: {} }

        item.values[key] = sanitizeValue(value)

        if (this.ttl) {
            const day = 1000 * 60 * 60 * 24
            item.expires = (this.ttl * day) + Date.now()
        }

        this.storage.setItem(
            this.section,
            JSON.stringify(item)
        )
    }

    /**
     * Gets an item from Session Storage
     */
    get(key: string): any {
        const getItem = this.storage.getItem(this.section)
        const item = getItem ? JSON.parse(getItem) : undefined

        if (!item || (item.expires && item.expires < Date.now())) {
            this.storage.removeItem(this.section)

            return undefined
        }

        return item.values[key]
    }

    /**
     * Removes an item from Session Storage
     */
    remove(key: string) {
        const getItem = this.storage.getItem(this.section)
        const item = getItem ? JSON.parse(getItem) : undefined

        if (!item) {
            return
        }

        item.values = omit(item.values, key)

        this.storage.setItem(
            this.section,
            JSON.stringify(item)
        )
    }

    /**
     * Removes all items from Session Storage
     */
    clear() {
        this.storage.removeItem(this.section)
    }

    /**
     * Clears all Session Storage
     */
    clearAll() {
        this.storage.clear()
    }
}

export default SessionStore
