export default {
  "components-page-title": () => import("/codebuild/output/src3746676769/src/christ-talker-ui/src/layouts/components/PageTitle.vue").then(m => m.default || m),
  "components-scroll-top": () => import("/codebuild/output/src3746676769/src/christ-talker-ui/src/layouts/components/ScrollTop.vue").then(m => m.default || m),
  "components-theme-mode-switcher": () => import("/codebuild/output/src3746676769/src/christ-talker-ui/src/layouts/components/ThemeModeSwitcher.vue").then(m => m.default || m),
  "components-toolbar": () => import("/codebuild/output/src3746676769/src/christ-talker-ui/src/layouts/components/Toolbar.vue").then(m => m.default || m),
  "components-user-profile-menu": () => import("/codebuild/output/src3746676769/src/christ-talker-ui/src/layouts/components/UserProfileMenu.vue").then(m => m.default || m),
  "components-footer": () => import("/codebuild/output/src3746676769/src/christ-talker-ui/src/layouts/components/footer/Footer.vue").then(m => m.default || m),
  "components-header": () => import("/codebuild/output/src3746676769/src/christ-talker-ui/src/layouts/components/header/Header.vue").then(m => m.default || m),
  "components-header-menu": () => import("/codebuild/output/src3746676769/src/christ-talker-ui/src/layouts/components/header/Menu.vue").then(m => m.default || m),
  "components-header-topbar": () => import("/codebuild/output/src3746676769/src/christ-talker-ui/src/layouts/components/header/Topbar.vue").then(m => m.default || m),
  "config-default-layout-config": () => import("/codebuild/output/src3746676769/src/christ-talker-ui/src/layouts/config/DefaultLayoutConfig.ts").then(m => m.default || m),
  "config-main-menu-config": () => import("/codebuild/output/src3746676769/src/christ-talker-ui/src/layouts/config/MainMenuConfig.ts").then(m => m.default || m),
  "config-helper": () => import("/codebuild/output/src3746676769/src/christ-talker-ui/src/layouts/config/helper.ts").then(m => m.default || m),
  "config-types": () => import("/codebuild/output/src3746676769/src/christ-talker-ui/src/layouts/config/types.ts").then(m => m.default || m),
  default: () => import("/codebuild/output/src3746676769/src/christ-talker-ui/src/layouts/default.vue").then(m => m.default || m)
}