import SwAlert, { SweetAlertOptions } from 'sweetalert2'

export default defineNuxtPlugin(() => {
    const alert = (options: SweetAlertOptions = {}) => {

        if (!options.icon) {
            options.icon = `error`
        }

        if (!options.buttonsStyling === undefined) {
            options.buttonsStyling = false
        }

        if (!options.confirmButtonText) {
            options.confirmButtonText = `Try again!`
        }

        if (!options.heightAuto === undefined) {
            options.heightAuto = false
        }

        if (!options.customClass) {
            options.customClass = {
                confirmButton: `btn fw-semibold btn-light-danger`
            }
        }

        return SwAlert.mixin(options)
    }

    return {
        provide: {
            alert: alert
        }
    }
})
