import { defineStore } from 'pinia'
import Cookie from '@/utils/cookie'
import SessionStorage from '@/utils/store'
import { AuthToken, User } from '@/types/auth'
import { ref, computed } from 'vue'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { deserializeUser } from '@/mappers/auth'

export const useAuthStore = defineStore(`auth`, () => {
    const cookie = new Cookie(`auth`, `/`, true, 10)
    const sessionStore = new SessionStorage(`auth`, 10)

    const { $api } = useNuxtApp()

    const initialAuthUser = sessionStore.get(`user`) as User || undefined
    const initialAuthToken = cookie.get(`auth_token`) as AuthToken || undefined

    const authUser = ref<User|undefined>(initialAuthUser)
    const authToken = ref<AuthToken|undefined>(initialAuthToken)

    const getAccessToken = computed(() => {
        return authToken.value?.accessToken
    })

    const getRefreshToken = computed(() => {
        return authToken.value?.refreshToken
    })

    const getUser = computed(() => {
        return authUser.value
    })

    async function fetchUserProfile(config: AxiosRequestConfig = {}): Promise<void> {
        return $api()
            .get(`/v1/me`, config)
            .then(({ data }: AxiosResponse) => {
                setUser(deserializeUser(data.data))
                return Promise.resolve()
            })
            .catch(e => {
                return Promise.reject(e)
            })
    }

    async function updateUserProfile(payload: any, config: AxiosRequestConfig = {}): Promise<void> {
        return $api()
            .patch(`/v1/me`, payload, config)
            .then(({ data }: AxiosResponse) => {
                setUser(deserializeUser(data.data))
                return Promise.resolve()
            })
            .catch(e => {
                return Promise.reject(e)
            })
    }

    function setAuthToken(token: AuthToken) {
        authToken.value = token
        cookie.set(`auth_token`, authToken.value)
        cookie.set(`access_token`)
    }

    function clearAuthToken() {
        cookie.clear()
        sessionStore.clear()
        authUser.value = undefined
        authToken.value = undefined

        navigateTo(`/`)
    }

    function setUser(user: User) {
        authUser.value = user
        sessionStore.set(`user`, authUser.value)
    }

    function logOut() {
        $api()
            .post(`/v1/logout`)
            .catch()
            .finally(() => clearAuthToken())
    }

    return {
        getAccessToken,
        getRefreshToken,
        getUser,
        setAuthToken,
        clearAuthToken,
        logOut,
        fetchUserProfile,
        updateUserProfile
    }
})
