import revive_payload_client_4sVQNw7RlN from "/codebuild/output/src3746676769/src/christ-talker-ui/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/codebuild/output/src3746676769/src/christ-talker-ui/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/codebuild/output/src3746676769/src/christ-talker-ui/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/codebuild/output/src3746676769/src/christ-talker-ui/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/codebuild/output/src3746676769/src/christ-talker-ui/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/codebuild/output/src3746676769/src/christ-talker-ui/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src3746676769/src/christ-talker-ui/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/codebuild/output/src3746676769/src/christ-talker-ui/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_yfWm7jX06p from "/codebuild/output/src3746676769/src/christ-talker-ui/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/codebuild/output/src3746676769/src/christ-talker-ui/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import api_0otAtqq586 from "/codebuild/output/src3746676769/src/christ-talker-ui/src/plugins/api.ts";
import alert_d0zG1fWOi4 from "/codebuild/output/src3746676769/src/christ-talker-ui/src/plugins/alert.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  api_0otAtqq586,
  alert_d0zG1fWOi4
]