import { default as dashboard6LRkUQGO3GMeta } from "/codebuild/output/src3746676769/src/christ-talker-ui/src/pages/dashboard.vue?macro=true";
import { default as errorQPDvrUynARMeta } from "/codebuild/output/src3746676769/src/christ-talker-ui/src/pages/error.vue?macro=true";
import { default as _91id_935oHWtvBqRLMeta } from "/codebuild/output/src3746676769/src/christ-talker-ui/src/pages/image-templates/[id].vue?macro=true";
import { default as indexARqrdKAhIlMeta } from "/codebuild/output/src3746676769/src/christ-talker-ui/src/pages/image-templates/index.vue?macro=true";
import { default as indexmt7VoZS7j7Meta } from "/codebuild/output/src3746676769/src/christ-talker-ui/src/pages/index.vue?macro=true";
import { default as loginRDT2qPeGpgMeta } from "/codebuild/output/src3746676769/src/christ-talker-ui/src/pages/login.vue?macro=true";
import { default as _91id_93MRrjYT3Gk7Meta } from "/codebuild/output/src3746676769/src/christ-talker-ui/src/pages/posts/[id].vue?macro=true";
import { default as addo2jAYNyhzGMeta } from "/codebuild/output/src3746676769/src/christ-talker-ui/src/pages/posts/add.vue?macro=true";
import { default as indexVmg9qcFjPDMeta } from "/codebuild/output/src3746676769/src/christ-talker-ui/src/pages/posts/index.vue?macro=true";
import { default as profileI1Bn0QuGd3Meta } from "/codebuild/output/src3746676769/src/christ-talker-ui/src/pages/profile.vue?macro=true";
import { default as _91id_933SQ7uMarFlMeta } from "/codebuild/output/src3746676769/src/christ-talker-ui/src/pages/scriptures/[id].vue?macro=true";
import { default as add3QAa775bZnMeta } from "/codebuild/output/src3746676769/src/christ-talker-ui/src/pages/scriptures/add.vue?macro=true";
import { default as indexaMZzQ09LxxMeta } from "/codebuild/output/src3746676769/src/christ-talker-ui/src/pages/scriptures/index.vue?macro=true";
import { default as _91id_9331TxoQCQrNMeta } from "/codebuild/output/src3746676769/src/christ-talker-ui/src/pages/social/[id].vue?macro=true";
import { default as addqx37XFOGfFMeta } from "/codebuild/output/src3746676769/src/christ-talker-ui/src/pages/social/add.vue?macro=true";
import { default as indexkCVSomPYCwMeta } from "/codebuild/output/src3746676769/src/christ-talker-ui/src/pages/social/index.vue?macro=true";
import { default as _91id_938eD5aEqqHAMeta } from "/codebuild/output/src3746676769/src/christ-talker-ui/src/pages/users/[id].vue?macro=true";
import { default as index92BdBGAaRMMeta } from "/codebuild/output/src3746676769/src/christ-talker-ui/src/pages/users/index.vue?macro=true";
export default [
  {
    name: dashboard6LRkUQGO3GMeta?.name ?? "dashboard",
    path: dashboard6LRkUQGO3GMeta?.path ?? "/dashboard",
    meta: dashboard6LRkUQGO3GMeta || {},
    alias: dashboard6LRkUQGO3GMeta?.alias || [],
    redirect: dashboard6LRkUQGO3GMeta?.redirect,
    component: () => import("/codebuild/output/src3746676769/src/christ-talker-ui/src/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: errorQPDvrUynARMeta?.name ?? "error",
    path: errorQPDvrUynARMeta?.path ?? "/error",
    meta: errorQPDvrUynARMeta || {},
    alias: errorQPDvrUynARMeta?.alias || [],
    redirect: errorQPDvrUynARMeta?.redirect,
    component: () => import("/codebuild/output/src3746676769/src/christ-talker-ui/src/pages/error.vue").then(m => m.default || m)
  },
  {
    name: _91id_935oHWtvBqRLMeta?.name ?? "image-templates-id",
    path: _91id_935oHWtvBqRLMeta?.path ?? "/image-templates/:id()",
    meta: _91id_935oHWtvBqRLMeta || {},
    alias: _91id_935oHWtvBqRLMeta?.alias || [],
    redirect: _91id_935oHWtvBqRLMeta?.redirect,
    component: () => import("/codebuild/output/src3746676769/src/christ-talker-ui/src/pages/image-templates/[id].vue").then(m => m.default || m)
  },
  {
    name: indexARqrdKAhIlMeta?.name ?? "image-templates",
    path: indexARqrdKAhIlMeta?.path ?? "/image-templates",
    meta: indexARqrdKAhIlMeta || {},
    alias: indexARqrdKAhIlMeta?.alias || [],
    redirect: indexARqrdKAhIlMeta?.redirect,
    component: () => import("/codebuild/output/src3746676769/src/christ-talker-ui/src/pages/image-templates/index.vue").then(m => m.default || m)
  },
  {
    name: indexmt7VoZS7j7Meta?.name ?? "index",
    path: indexmt7VoZS7j7Meta?.path ?? "/",
    meta: indexmt7VoZS7j7Meta || {},
    alias: indexmt7VoZS7j7Meta?.alias || [],
    redirect: indexmt7VoZS7j7Meta?.redirect,
    component: () => import("/codebuild/output/src3746676769/src/christ-talker-ui/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginRDT2qPeGpgMeta?.name ?? "login",
    path: loginRDT2qPeGpgMeta?.path ?? "/login",
    meta: loginRDT2qPeGpgMeta || {},
    alias: loginRDT2qPeGpgMeta?.alias || [],
    redirect: loginRDT2qPeGpgMeta?.redirect,
    component: () => import("/codebuild/output/src3746676769/src/christ-talker-ui/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: _91id_93MRrjYT3Gk7Meta?.name ?? "posts-id",
    path: _91id_93MRrjYT3Gk7Meta?.path ?? "/posts/:id()",
    meta: _91id_93MRrjYT3Gk7Meta || {},
    alias: _91id_93MRrjYT3Gk7Meta?.alias || [],
    redirect: _91id_93MRrjYT3Gk7Meta?.redirect,
    component: () => import("/codebuild/output/src3746676769/src/christ-talker-ui/src/pages/posts/[id].vue").then(m => m.default || m)
  },
  {
    name: addo2jAYNyhzGMeta?.name ?? "posts-add",
    path: addo2jAYNyhzGMeta?.path ?? "/posts/add",
    meta: addo2jAYNyhzGMeta || {},
    alias: addo2jAYNyhzGMeta?.alias || [],
    redirect: addo2jAYNyhzGMeta?.redirect,
    component: () => import("/codebuild/output/src3746676769/src/christ-talker-ui/src/pages/posts/add.vue").then(m => m.default || m)
  },
  {
    name: indexVmg9qcFjPDMeta?.name ?? "posts",
    path: indexVmg9qcFjPDMeta?.path ?? "/posts",
    meta: indexVmg9qcFjPDMeta || {},
    alias: indexVmg9qcFjPDMeta?.alias || [],
    redirect: indexVmg9qcFjPDMeta?.redirect,
    component: () => import("/codebuild/output/src3746676769/src/christ-talker-ui/src/pages/posts/index.vue").then(m => m.default || m)
  },
  {
    name: profileI1Bn0QuGd3Meta?.name ?? "profile",
    path: profileI1Bn0QuGd3Meta?.path ?? "/profile",
    meta: profileI1Bn0QuGd3Meta || {},
    alias: profileI1Bn0QuGd3Meta?.alias || [],
    redirect: profileI1Bn0QuGd3Meta?.redirect,
    component: () => import("/codebuild/output/src3746676769/src/christ-talker-ui/src/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: _91id_933SQ7uMarFlMeta?.name ?? "scriptures-id",
    path: _91id_933SQ7uMarFlMeta?.path ?? "/scriptures/:id()",
    meta: _91id_933SQ7uMarFlMeta || {},
    alias: _91id_933SQ7uMarFlMeta?.alias || [],
    redirect: _91id_933SQ7uMarFlMeta?.redirect,
    component: () => import("/codebuild/output/src3746676769/src/christ-talker-ui/src/pages/scriptures/[id].vue").then(m => m.default || m)
  },
  {
    name: add3QAa775bZnMeta?.name ?? "scriptures-add",
    path: add3QAa775bZnMeta?.path ?? "/scriptures/add",
    meta: add3QAa775bZnMeta || {},
    alias: add3QAa775bZnMeta?.alias || [],
    redirect: add3QAa775bZnMeta?.redirect,
    component: () => import("/codebuild/output/src3746676769/src/christ-talker-ui/src/pages/scriptures/add.vue").then(m => m.default || m)
  },
  {
    name: indexaMZzQ09LxxMeta?.name ?? "scriptures",
    path: indexaMZzQ09LxxMeta?.path ?? "/scriptures",
    meta: indexaMZzQ09LxxMeta || {},
    alias: indexaMZzQ09LxxMeta?.alias || [],
    redirect: indexaMZzQ09LxxMeta?.redirect,
    component: () => import("/codebuild/output/src3746676769/src/christ-talker-ui/src/pages/scriptures/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_9331TxoQCQrNMeta?.name ?? "social-id",
    path: _91id_9331TxoQCQrNMeta?.path ?? "/social/:id()",
    meta: _91id_9331TxoQCQrNMeta || {},
    alias: _91id_9331TxoQCQrNMeta?.alias || [],
    redirect: _91id_9331TxoQCQrNMeta?.redirect,
    component: () => import("/codebuild/output/src3746676769/src/christ-talker-ui/src/pages/social/[id].vue").then(m => m.default || m)
  },
  {
    name: addqx37XFOGfFMeta?.name ?? "social-add",
    path: addqx37XFOGfFMeta?.path ?? "/social/add",
    meta: addqx37XFOGfFMeta || {},
    alias: addqx37XFOGfFMeta?.alias || [],
    redirect: addqx37XFOGfFMeta?.redirect,
    component: () => import("/codebuild/output/src3746676769/src/christ-talker-ui/src/pages/social/add.vue").then(m => m.default || m)
  },
  {
    name: indexkCVSomPYCwMeta?.name ?? "social",
    path: indexkCVSomPYCwMeta?.path ?? "/social",
    meta: indexkCVSomPYCwMeta || {},
    alias: indexkCVSomPYCwMeta?.alias || [],
    redirect: indexkCVSomPYCwMeta?.redirect,
    component: () => import("/codebuild/output/src3746676769/src/christ-talker-ui/src/pages/social/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_938eD5aEqqHAMeta?.name ?? "users-id",
    path: _91id_938eD5aEqqHAMeta?.path ?? "/users/:id()",
    meta: _91id_938eD5aEqqHAMeta || {},
    alias: _91id_938eD5aEqqHAMeta?.alias || [],
    redirect: _91id_938eD5aEqqHAMeta?.redirect,
    component: () => import("/codebuild/output/src3746676769/src/christ-talker-ui/src/pages/users/[id].vue").then(m => m.default || m)
  },
  {
    name: index92BdBGAaRMMeta?.name ?? "users",
    path: index92BdBGAaRMMeta?.path ?? "/users",
    meta: index92BdBGAaRMMeta || {},
    alias: index92BdBGAaRMMeta?.alias || [],
    redirect: index92BdBGAaRMMeta?.redirect,
    component: () => import("/codebuild/output/src3746676769/src/christ-talker-ui/src/pages/users/index.vue").then(m => m.default || m)
  }
]