<script setup lang="ts">
import { nextTick, onBeforeMount, onMounted } from "vue"
import { useConfigStore } from "@/store/config"
import { useThemeStore } from "@/store/theme"
import { useBodyStore } from "@/store/body"
import { themeConfigValue } from "@/layouts/config/helper"
import { initializeComponents } from "@/utils/keenthemes"

const configStore = useConfigStore()
const themeStore = useThemeStore()
const bodyStore = useBodyStore()

onBeforeMount(() => {
    /**
     * Overrides the layout config using saved data from localStorage
     * remove this to use static config (@/layouts/default-layout/config/DefaultLayoutConfig.ts)
     */
    configStore.overrideLayoutConfig()

    /**
     *  Sets a mode from configuration
     */
    themeStore.setThemeMode(themeConfigValue.value)
})

onMounted(() => {
    nextTick(() => {
        initializeComponents()

        bodyStore.removeBodyClassName(`page-loading`)
    })
})
</script>

<template>
    <div>
        <NuxtLayout>
            <NuxtPage/>
        </NuxtLayout>
    </div>
</template>

<style lang="scss">
@import "bootstrap-icons/font/bootstrap-icons.css";
@import "apexcharts/dist/apexcharts.css";
@import "sweetalert2/dist/sweetalert2.css";
@import "@fortawesome/fontawesome-free/css/all.min.css";
@import "element-plus/dist/index.css";

// Main demo style scss
@import "assets/keenicons/duotone/style.css";
@import "assets/keenicons/outline/style.css";
@import "assets/keenicons/solid/style.css";
@import "assets/sass/element-ui.dark";
@import "assets/sass/plugins";
@import "assets/sass/app.scss";

#app {
    display: contents;
}
</style>
