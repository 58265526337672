import {RefreshTokenResponse, UserProfileResponse} from '@/types/auth/api'
import {AuthToken, User} from '@/types/auth'

const deserializeAuthToken = (data: RefreshTokenResponse): AuthToken => {
    return {
        accessToken: data.access_token,
        refreshToken: data.refresh_token
    }
}

const deserializeUser = (user: UserProfileResponse): User => {
    return {
        id: user.id,
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        admin: user.type === `admin`,
        countryCode: user.country_code,
        locale: user.locale,
        allowNotifications: user.allow_notifications
    }
}

export {
    deserializeAuthToken,
    deserializeUser
}
