import Cookies from 'js-cookie'
import { get, isObjectLike } from 'lodash-es'

const parseCookie = (value?: string) => JSON.parse(value || `{}`)

class Cookie {
    cookieInstance: Cookies.CookiesStatic
    section: string
    path: string
    secure: boolean
    expires?: number

    constructor(section: string, path: string, secure: boolean, expires?: number) {
        this.section = `christ-talker-${section}`
        this.path = path
        this.expires = expires
        this.secure = secure

        this.cookieInstance = Cookies.withAttributes({
            path: this.path,
            sameSite: `none`,
            secure: this.secure,
            ...(this.expires ? { expires: this.expires } : {})
        })
    }

    /**
     * Gets the current value of a key
     */
    get(key: string): any {
        const cookie = parseCookie(this.cookieInstance.get(this.section))

        return get(cookie, key, null)
    }

    /**
     * Sets the value of a key
     * Passing no value will delete the key:value pair
     */
    set(key: string, value?: any) {
        let cookie = parseCookie(this.cookieInstance.get(this.section))

        if (!isObjectLike(cookie)) {
            cookie = {}
        }

        cookie[key] = value

        this.cookieInstance.set(this.section, JSON.stringify(cookie))
    }

    /**
     * Clears all values for the given name
     */
    clear() {
        this.cookieInstance.remove(this.section)
    }
}

export default Cookie
